import css from 'styled-jsx/css';

export default css`
.container {
  margin: 0 auto;
  text-align: left;
  padding: 25px 15px;
  box-sizing: border-box;
}

.container-header {
  font-size: 30px;
  font-weight: 600;
  color: #8d8d8f;
  text-align: center;
  margin-bottom: 20px;
}

.d-flex {
  display: flex;
}

justify-content-center {
  justify-content: center;
}

justify-content-start {
  justify-content: flex-end;
}

align-items-center {
  align-items: center;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.accountAlready {
    margin-top:25px;
}

#accountAlready {
  color: #2892cc;
  cursor: pointer;
  font-size: 13px;
}

.submit-button {
  width: 100%;
  padding: 12px 8px;
  box-sizing: border-box;
  cursor: pointer;
  color: #ffffff;
  border: none;
  background-color: #E74C3C;
}

.links {
  color: #2892cc;
}

.policy {
  margin-top: 25px;
  text-align: center;
}

.form-text {
  font-size: 24px;
  margin-bottom: 25px;
}

.forgotPassword {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.form-title {
  font-size: 21px;
  color: #8d8d8f;
  margin-top: 40px;
  margin-bottom: 25px;
}

.phone-label {
  color: #212b36;
  margin-bottom: 10px;
  font-weight: 400;
}

@media (min-width: 600px) {
  .container-header {
    font-size: 40px;
  }

  .submit-button {
    padding: 16px 10px;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 600;
    min-height: 50px;
    width: 50%;
    border: none;
  }

  .phone-label {
    font-size: 16px;
  }

  #accountAlready {
    font-size: 14px;
  }
}

@media (min-width:480px) {
  .container {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
`;
