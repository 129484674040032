import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMe, resetUser } from '../../actions/app';
import GifLoaderComponent from '../../components/GifLoader';
import { Router } from '../../../routes';
import PropTypes from 'prop-types';
import jwtService from '../../api/jwtService';
import appActionTypes from '../../types/app';

class SetUserLayout extends Component {
  componentDidMount() {
    const { fetchMe, isUserValid, appReady } = this.props;

    if (!isUserValid && jwtService.hasAccessToken()) {
      fetchMe();
    } else {
      appReady();
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.userError) {
      jwtService.removeAccessToken();
      jwtService.removeRefreshToken();
      Router.push('/');
      nextProps.resetUser();
    }

    return nextProps.isAppReady || nextProps.isUserValid || nextProps.userError;
  }

  render() {
    const { isAppReady } = this.props;

    if (!isAppReady) {
      return (<GifLoaderComponent />);
    }

    return (
      <React.Fragment>
        { this.props.children }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    app: appState
  } = state;

  return {
    isAppReady: appState.isAppReady,

    isUserFetching: appState.isUserFetching,
    isUserValid: appState.isUserValid,
    userError: appState.userError
  }
};

const mapDispatchToActions = (dispatch) => ({
  fetchMe: fetchMe(dispatch),
  resetUser: resetUser(dispatch),
  appReady: () => dispatch({ type: appActionTypes.APP_READY, payload: { isAppReady: true }})
});

SetUserLayout.defaultProps = {
  children: null
};

SetUserLayout.propTypes = {
  children: PropTypes.node
};

export default connect(mapStateToProps, mapDispatchToActions)(SetUserLayout);
