import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
  .alert-wrapper {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .35);
    z-index: 101;
    padding: 10px;
    box-sizing: border-box;
  }

  .alert {
    border-radius: 8px;
    border: 1px solid ${colors.frameColor};
    position: relative;
    padding: 20px 15px 15px;
    background-color: white;
    max-width: 100%;
    box-sizing: border-box;
    animation-name: popup-animation;
    animation-duration: .2s;
    animation-timing-function: linear;
    min-width: 320px;
  }

  .alert > button {
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  .title {
    padding: 20px 0 0;
    color: ${colors.titleColor};
    font-family: "Roboto Medium";
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    word-break: break-all;
  }

  .content {
    margin-top: 40px;
    padding: 10px 10px 30px;
    background-color: ${colors.alertContent};
    color: ${colors.alertContentText};
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 2px grey;
    max-width: 650px;
    min-wdith: 280px;
    overflow: hidden;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 36px;
  }

  .button-wrapper span {
    margin: 5px 5px;
  }

  @keyframes popup-animation {
    from {top: -100px; opacity: 0;}
    to {top: 0; opacity: 1;}
  }
`;
