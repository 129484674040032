import React from 'react';
import {connect} from 'react-redux'

const decorator = (ComposedComponent) => {    
    class Wrapper extends React.Component {
        constructor(props) {
            super(props);
        }

        render () {
            return (
                <React.Fragment>
                    <ComposedComponent  {...this.props}/>
                </React.Fragment>
            )
        }
    }
    let stateToPropsMapper = state => {
        let temp = Object.assign({}, state.portals);

        return temp;
    };
    let ConnectedWrapper;

    ConnectedWrapper = connect(stateToPropsMapper)(Wrapper);

    return <ConnectedWrapper />;
}

export default decorator;