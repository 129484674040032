import css from 'styled-jsx/css';

export default css`
.login-container {
  display: flex;
  flex-flow: nowrap column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.login-container > label {
  color: #212b36;
  margin-bottom: 10px;
  font-weight: 400;
}

.login-container > .default-input {
  flex: 1;
  padding: 12px 8px;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  margin-bottom: 5px;
  font-size: 13px;
  box-sizing: border-box;
  width: 100%;
}

.login-container > :global(.mask-input) {
  flex: 1;
  padding: 12px 8px;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  font-size: 12px;
  box-sizing: border-box;
  width: 100%;
}

.login-container > .select-input {
  padding: 12px 8px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid #dfdfdf;
  background:none;
  font-weight: 400;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .login-container > label {
    font-size: 16px;
  }

  .login-container > .default-input {
    padding: 16px 10px;
    font-size: 12px;
    font-weight: 500;
    min-height: 50px;
  }

  .login-container > :global(.mask-input) {
    padding: 16px 10px;
    font-size: 13px;
    font-weight: 500;
    min-height: 50px;
  }

  .login-container > .select-input {
    padding: 16px 10px;
    font-size: 13px;
    min-height: 50px;
    width: 100%;
  }
}
`;
