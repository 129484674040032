import css from 'styled-jsx/css';

export default css`
.app_layout {
  display: flex;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.app_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  background: rgba(110, 185, 220, 0.12);
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
}
`;
