import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import authModalTypes from '../../constants/authModalTypes';
import styles from './styles';
import { Form, Field, reduxForm } from 'redux-form';
import FormField from '../ReduxForms/FormField';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';
import {required, exactPassword, password} from '../ReduxForms/validators';

const ResetPassword = (props) => {
  const { t, handleSubmit, changeModal, error, submitting, onSubmit } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_resetPasswordText')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form-text">

          </p>
          <Field name="password" type="password" component={FormField} label="new password" t={t} validate={[required, password]} />
          <Field name="confirmPassword" type="password" component={FormField} label="confirm password" t={t} validate={[required, password, exactPassword]} />

          <FormError error={error} />

          <SubmitButton text={_.toUpper(t('label_next'))} disabled={submitting} />
        </Form>
      </div>

      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: 'resetPassword'
})(ResetPassword);
