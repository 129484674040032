import * as _ from 'lodash';
import countryPhone from '../../constants/countryPhone';
import { t } from 'i18next';

export const required = (value) => value ? undefined : t('validator.required');

export const email = (value) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? t('validator.email')
  : undefined;

export const password = (value) => value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)
  ? " "
  : undefined;

export const maxLength = (max) => (value) => value && value.trim().length > max
  ? t('validator.maxLength', { max })
  : undefined;

export const minLength = (min) => (value) => value && value.trim().length < min
  ? t('validator.minLength', { min })
  : undefined;

export const exactLength = (len) => (value) => value && value.trim().length !== len
  ? t('validator.exactLength', { len })
  : undefined;

export const numeric = (value) => value && !/^[0-9]*$/.test(value)
  ? t('validator.numeric')
  : undefined;

export const exactPassword = (value, values) => value && values.password !== value
  ? t('validator.exactPassword')
  : undefined;

export const min3 = minLength(3);

export const phone = (value, values) => {
  const phone = values.phone;

  if (!phone.country) {
    return t('validator.phone.selectCountry');
  }

  if (!phone) {
    return t('validator.phone.invalidField');
  }

  const countrySettings = _.find(countryPhone, { countryCode: phone.country });

  const formattedPhone = value.replace(/ /g, '');
  const formattedMask = countrySettings.phoneMask.replace(/ /g, '');

  return formattedPhone.length !== formattedMask.length
    ? t('validator.phone.invalidPhone')
    : undefined;
};

export default {
  required,
  email,
  password,
  maxLength,
  minLength,
  exactLength,
  min3,
  phone
}
