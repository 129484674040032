import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import { Form, reduxForm } from 'redux-form';
import SubmitButton from '../ReduxForms/SubmitButton';

const Message = (props) => {
  const { t, header, message, handleSubmit, submitting, onSubmit = () => '' } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t(header)}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form-title" align="center">
            {t(message)}
          </p>
          <SubmitButton text={_.toUpper(t('label_okay'))} disabled={submitting} />
        </Form>
      </div>
      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: 'message'
})(Message);
