import appTypes from '../types/app';
import act from './generic';
import appService from '../api/appService';
import jwtService from '../api/jwtService';
import { Router } from '../../routes';
import handleError from '../utils/handleError';

export const fetchMe = (dispatch) => async () => {
  try {
    dispatch(act(appTypes.USER_FETCH));
    const user = await appService.fetchMe();
    dispatch(act(appTypes.USER_RECEIVE, { user }));
  } catch (e) {
    dispatch(act(appTypes.USER_ERROR, { errorMessage: 'error' }));
    handleError.logError(e);
  }
};

export const resetUser = (dispatch) => () => dispatch(act(appTypes.USER_RESET));

export const logout = (dispatch) => () => {
  dispatch(act(appTypes.LOGOUT));
  jwtService.removeAccessToken();
  jwtService.removeRefreshToken();
  Router.push('/error', '/');
};
