import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import { Form, Field, reduxForm } from 'redux-form';
import FormField from '../ReduxForms/FormField';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';
import { required, email } from '../ReduxForms/validators';

const ForgotPassword = (props) => {
  const { t, handleSubmit, error, submitting, onSubmit } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_forgotPassword')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form-title">
            {t('label_forgotPasswordText')}
          </p>

          <Field name="email" component={FormField} placeholder={t('label_email')} t={t} validate={[required, email]} />

          <FormError error={error} />

          <SubmitButton containerClass="reset" text={_.toUpper(t('label_resetPassword'))} disabled={submitting} />
        </Form>
      </div>
      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: 'forgotPassword'
})(ForgotPassword);
