import React from 'react';

import Ionicon from 'react-ionicons';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from './styles';
import colors from '../../constants/colors';


const DEFAULT_MAX_LENGTH = 50;

class InputBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectionList: false,
    };
  }

  showSelectionList(e) {
    e.preventDefault();
    this.setState({ showSelectionList: true });
  }

  hideSelectionList() {
    this.setState({ showSelectionList: false });
  }

  selectValue(e, value) {
    e.stopPropagation();
    this.props.valueSetter(value);
    this.setState({ showSelectionList: false });
  }

  render() {
    return (
      <div className={
          `input-box ${this.props.errorHint ? 'wrong' : ''}
                      ${this.props.longLabel ? 'long-label' : ''}
                      ${this.props.topLabel ? 'top-label' : ''}
                      ${this.props.light ? 'light' : ''}
                      ${this.props.required ? 'required' : ''}`
          }
      >
        {
          this.props.label ?
            <div className="label">
              {this.props.label}
            </div>
        : ''
        }
        {
          <div className={`input-area 
              ${this.props.label ? 'with-label' : ''}
              ${this.props.slider ? 'slider' : ''}
              ${this.props.iconName ? 'with-icon' : ''}`}
          >
            {
              !this.props.rows ? '' :
              <textarea
                rows={this.props.rows}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={() => {}}
                disabled={!!this.props.disabled}
                onInput={e => this.props.valueSetter(e.target.value)}
                 />
            }
            {
              !this.props.slider
                ? <input
                  placeholder={this.props.placeholder}
                  value={this.props.value}
                  type={this.props.type || 'text'}
                  pattern={this.props.pattern || ''}
                  onChange={() => {}}
                  maxLength={this.props.maxLength || DEFAULT_MAX_LENGTH}
                  disabled={!!this.props.disabled || !!this.props.dataList.length}
                  onInput={e => this.props.valueSetter(e.target.value)}
                />
                :
                <div className="slider-wrapper">
                  <Slider
                    value={(+this.props.value || 0)}
                    onChange={value => this.props.valueSetter(value)}
                    min={this.props.min || 0}
                    max={this.props.max || 100}
                    step={this.props.step || 1}
                    trackStyle={{
                      height: 1,
                      backgroundColor: colors.buttonColor,
                    }}
                    handleStyle={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'rgba(0, 0, 0, .1)',
                      borderRadius: '50%',
                      height: 19,
                      width: 19,
                      marginLeft: -14,
                      marginTop: -11,
                      backgroundColor: 'white',
                      position: 'relative',
                      outline: 'none',
                    }}
                    />
                  <div>
                    {this.props.value}
                  </div>
                </div>
            }
            {
              this.props.iconName
                ?
                  <button onClick={this.props.valueSelector || (e => this.showSelectionList(e))}>
                    <Ionicon icon={this.props.iconName} fontSize="20px" color={this.props.iconColor || colors.iconColor} />
                  </button>
                : ''
            }
            {
              !this.state.showSelectionList ? '' :
              <React.Fragment>
                <div className="glass" onClick={() => this.hideSelectionList()} />
                <div className="dropdown">
                  {
                        this.props.dataList.map((el, i) => (
                          <div key={i} onClick={e => this.selectValue(e, el)}>
                            {el.view || (`${el}`)}
                          </div>
                        ))
                      }
                </div>
              </React.Fragment>
            }
            {
              !this.props.errorHint ? '' :
              <div className="hint">
                {this.props.errorHint}
                <span />
              </div>
            }
          </div>
        }
        <style jsx>{styles}</style>
      </div>
    )
    ;
  }
}

InputBox.defaultProps = {
  highlightWrong: false,
  dataList: [],
};

export default InputBox;
