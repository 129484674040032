import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import authModalTypes from '../../constants/authModalTypes';
import styles from './styles';
import { Form, Field, reduxForm } from 'redux-form';
import FormField from '../ReduxForms/FormField';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';
import { Link } from '../../../routes';

const SignIn = (props) => {
  const { t, handleSubmit, changeModal, error, submitting, onSubmit } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_login')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Field name="email" component={FormField} label="email" t={t} />
          <Field name="password" component={FormField} label="password" type="password" t={t} />

          <FormError error={error} />

          <SubmitButton text={_.toUpper(t('label_login'))} disabled={submitting} />
        </Form>

        <div className="forgotPassword">
          <button
            id="forgotPassword"
            className="no_button"
            onClick={() => changeModal(authModalTypes.forgotPassword)}
          >
            {t('label_forgotPassword')}
          </button>
        </div>

        <div className="accountAlready">
          <button
            id="accountAlready"
            className="no_button"
            onClick={() => changeModal(authModalTypes.signUp)}
          >
            {t('label_noAccountYet')}
          </button>
        </div>

        <div className="policy">
          <p>
            {t('label_policy1')}
            <p></p>
            <a className="footerLinks" href="https://softrey.ch/dsg/Softrey-AGB-Sep-2023-DE.pdf">
              {t('label_termsOfServices')}
            </a>
            {t('label_policy2')}
            <a className="footerLinks" href="https://softrey.ch/dsg/Softrey-DSE-Sep-2023-DE.pdf">
              {t('label_privacyPolicy')}
            </a>
            <p></p>
            {t('label_policy3')}
          </p>
        </div>

      </div>
      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: 'signIn'
})(SignIn);
