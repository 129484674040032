import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import appActionTypes from '../../types/app';
import authModalTypes from '../../constants/authModalTypes';
import countryPhone from '../../constants/countryPhone';
import { SubmissionError } from 'redux-form';
import appService from '../../api/appService';
import jwtService from '../../api/jwtService';
import { Router } from '../../../routes';
import SignIn from './SignIn';
import Verification from './Verification';
import VerificationEmail from './VerificationEmail';
import Message from './Message';
import Code from './Code';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import SpecialSignUp from './SpecialSignUp';
import ForgotPassword from './ForgotPassword';
import ChangePhone from './ChangePhone';
import SanamiaMig from './SanamiaMig';
import { fetchMe } from '../../actions/app';
import * as _ from 'lodash';
import portals from '../../utils/portals';

class AuthModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phone: null,
      email: null,
      userId: null,
      token: null,
      header: '',
      message: '',
      submitTriggered: false,
      isValidPhone: false,
    };

    this.changeModal = this.changeModal.bind(this);
    this.onSignInSubmit = this.onSignInSubmit.bind(this);
    this.onVerificationSubmit = this.onVerificationSubmit.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onSendCodeSubmit = this.onSendCodeSubmit.bind(this);
    this.onForgotPasswordSubmit = this.onForgotPasswordSubmit.bind(this);
    this.onResetPasswordSubmit = this.onResetPasswordSubmit.bind(this);
    this.onSignUpSubmit = this.onSignUpSubmit.bind(this);
    this.onChangePhoneSubmit = this.onChangePhoneSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      if(Router.router && Router.router.query.modal) {
        this.changeModal(Router.router.query.modal);
      }
      if (Router.router && Router.router.query.hash && Router.router.query.modal === 'signUp') {
        let contact = await appService.getContactFromHash(Router.router.query.hash);
        this.props.setSignUpData(contact);
      }
      if (Router.router && Router.router.query.token && Router.router.query.modal === 'resetPassword') {
        this.setState({token: Router.router.query.token});
      }
    } catch (error) {
      console.error(e);
      portals.alertShort('Invalid hash', () => {
        Router.replace('/');
      })
    }

    try {
      if (Router.router && Router.router.query.key) {
        const userInfo = await appService.checkSignUpKey(Router.router.query.key);

        const user = _.pick(userInfo, ['email', 'firstName', 'lastName', 'phone']);
        if (user.phone) {
          const country = _.find(countryPhone, (el) => _.startsWith(user.phone, el.countryCode));

          user.phone = {
            country: user.phone.slice(0, country.countryCode.length),
            phone: user.phone.slice(country.countryCode.length)
          }
        }

        this.props.setSpecialSignUpData(user);
        this.changeModal(authModalTypes.specialSignUp);
      }
    } catch (e) {
      console.error(e);
      portals.alertShort('Invalid key', () => {
        Router.replace('/');
      })
    }
  }

  changeModal(name = null) {
    this.props.authModalChange(name);
  }

  errorHandler(e) {
    if (!e.response) {
      throw new SubmissionError({ _error: 'An error occurred' });
    }

    const { status, data: { error } } = e.response;

    if (status >= 400 && status < 500) {
      if (error.userId && error.isSmsAttemptsExhausted) {
        this.setState({ userId: error.userId }, () => this.changeModal(authModalTypes.verificationEmail));
        return;
      }

      if (error.userId) {
        this.setState({ userId: error.userId }, () => this.changeModal(authModalTypes.verification));
        return;
      }

      const errorMessage = _.isArray(error.message) ? error.message[0].message : error.message;
      throw new SubmissionError({ _error: errorMessage });
    } else if (status >= 500) {
      throw new SubmissionError({ _error: 'Server error' });
    }

    throw new SubmissionError({ _error: 'An error occurred' });
  }

  async onSignInSubmit(form) {
    try {
      const { email, password } = form;

      if (!email || !password) {
        return;
      }

      const { signInToken, refreshToken } = await appService.signIn(form);

      jwtService.setAccessToken(signInToken);
      jwtService.setRefreshToken(refreshToken);

      if (Router.router && Router.router.pathname === '/') {
        Router.push('/dashboard/dashboard');
      } else {
        await this.props.fetchMe();
      }

      this.changeModal(authModalTypes.close);
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async onVerificationSubmit() {
    if(this.state.userId){
      try {
        await appService.sendActivationCode(this.state.userId);
        this.changeModal(authModalTypes.code);
      } catch (e) {
        this.errorHandler(e);
      }
    }
  }

  onVerificationEmailSubmit = async () => {
    try {
      await appService.sendActivationCode(this.state.userId);
      this.changeModal(authModalTypes.code);
    } catch (e) {
      this.errorHandler(e);
    }
  };

  async onMessageSubmit() {
    try {
      this.changeModal(authModalTypes.close);
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async onSendCodeSubmit(form) {
    try {
      const { code } = form;
      let langKey = localStorage.getItem('i18nextLng') || 'de';
      if (langKey.indexOf('-') !== -1) {
        langKey = langKey.split('-')[0];
      }
      const { signInToken, refreshToken } = await appService.activateWithCode({
        code,
        userId: this.state.userId,
        langKey
      });

      jwtService.setAccessToken(signInToken);
      jwtService.setRefreshToken(refreshToken);

      if (Router.router.pathname === '/') {
        Router.push('/dashboard/dashboard');
      } else {
        await this.props.fetchMe();
      }

      this.changeModal(authModalTypes.close);
      this.setState({ userId: null, phone: null });
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async onForgotPasswordSubmit(form) {
    try {
      const { email } = form;

      await appService.sendResetPasswordLink(email);

      this.setState({header:'label_resetPasswordText', message:'label_verifyPasswordMessage'}, this.changeModal(authModalTypes.message));
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async onResetPasswordSubmit(form) {
    try {
      const { password } = form;

      await appService.resetPassword(password, this.state.token);

      Router.replace('/');

      this.setState({header:'label_resetPasswordText', message:'label_passwordIsChanged'}, this.changeModal(authModalTypes.message));
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async onChangePhoneSubmit(form) {
    try {
      const { phone, country } = form.phone;
      const newPhone = `${phone}`.replace(/[ +]/g, '');
      const user = await appService.changePhone(this.state.userId, { phone: newPhone });

      this.setState({ phone: user.phone }, () => this.changeModal(authModalTypes.verification));
    } catch (e) {
      this.errorHandler(e);
    }
  }

  async onSignUpSubmit(form, dispatch, formProps) {
    if (!this.state.isValidPhone) {
      this.setState({ submitTriggered: true });
      return;
    }

    try {
      const { phone, country } = form.phone;
      let langKey = localStorage.getItem('i18nextLng') || 'de';
      if (langKey.indexOf('-') !== -1) {
        langKey = langKey.split('-')[0];
      }

      const dataToSignUp = {
        email: form.email,
        password: form.password,
        phone: `${phone}`.replace(/[ +]/g, ''),
        countryPhone: country,
        firstName: form.firstName,
        lastName: form.lastName,
        langKey
      };

      const isCommonSignUp = formProps.form === authModalTypes.signUp;

      const signUpRequest = isCommonSignUp
        ? appService.signUp
        : appService.specialSignUp;

      const { user } = await signUpRequest(dataToSignUp);
      this.setState({
        userId: user.user_id,
        phone: user.phone,
        email: user.email,
      }, () => this.changeModal(authModalTypes.verification));

      if (!isCommonSignUp) {
        Router.replace('/');
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  setIsValidPhone = (isValid) => {
    this.setState({ isValidPhone: isValid });
  };

  renderDialogContent() {
    const { state: { phone, email }, props: { authModalName, t }} = this;

    switch (authModalName) {
      case authModalTypes.signIn:
        return <SignIn t={t} changeModal={this.changeModal} onSubmit={this.onSignInSubmit} />;
      case authModalTypes.signUp:
        return <SignUp t={t} changeModal={this.changeModal} onSubmit={this.onSignUpSubmit} submitTriggered={this.state.submitTriggered} setIsValidPhone={this.setIsValidPhone} />;
      case authModalTypes.specialSignUp:
        return <SpecialSignUp t={t} changeModal={this.changeModal} onSubmit={this.onSignUpSubmit} submitTriggered={this.state.submitTriggered} setIsValidPhone={this.setIsValidPhone} />;
      case authModalTypes.verification:
        return <Verification t={t} changeModal={this.changeModal} onSubmit={this.onVerificationSubmit} phone={phone} />;
      case authModalTypes.verificationEmail:
        return <VerificationEmail t={t} onSubmit={this.onVerificationEmailSubmit} email={email} />;
      case authModalTypes.message:
        return <Message t={t} header={this.state.header} message={this.state.message} changeModal={this.changeModal} onSubmit={this.onMessageSubmit} />;
      case authModalTypes.code:
        return <Code t={t} changeModal={this.changeModal} onSubmit={this.onSendCodeSubmit} />;
      case authModalTypes.forgotPassword:
        return <ForgotPassword t={t} onSubmit={this.onForgotPasswordSubmit} />;
      case authModalTypes.resetPassword:
        return <ResetPassword t={t} changeModal={this.changeModal} onSubmit={this.onResetPasswordSubmit} />;
      case authModalTypes.changePhone:
        return <ChangePhone t={t} changeModal={this.changeModal} onSubmit={this.onChangePhoneSubmit} />;
      case authModalTypes.sanamiaMig:
        return <SanamiaMig t={t} changeModal={this.changeModal} hash={Router.router.query.hash} />;
      default:
        return <React.Fragment />;
    }
  }

  render() {
    return (
      <Dialog
        open={!!this.props.authModalName}
        onClose={() => this.changeModal(authModalTypes.close)}
        fullWidth={true}
      >
        {this.renderDialogContent()}
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authModalName: state.app.authModalName
  };
};

const mapDispatchToActions = (dispatch) => ({
  authModalChange: (name) => dispatch({ type: appActionTypes.AUTH_MODAL_CHANGE, payload: { name } }),
  setUser: (user) => dispatch({ type: appActionTypes.USER_SET, payload: { user }}),
  fetchMe: fetchMe(dispatch),
  setSignUpData: (data) => dispatch({ type: appActionTypes.SET_SIGN_UP_DATA, payload: { signUpData: data }}),
  setSpecialSignUpData: (data) => dispatch({ type: appActionTypes.SET_SPECIAL_SIGN_UP_DATA, payload: { specialSignUpData: data }}),
});

export default connect(mapStateToProps, mapDispatchToActions)(AuthModal);
