import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import authModalTypes from '../../constants/authModalTypes';
import styles from './styles';
import { Form, reduxForm } from 'redux-form';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';

const Verification = (props) => {
  const { t, handleSubmit, phone, error, submitting, onSubmit, changeModal } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_verifyByPhone')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form-title" align="center">
            {t('label_verifyMessage')}
            <br />
            {phone ? `+${phone}` : t('label_yourPhone')}
          </p>

          <FormError error={error} />

          <SubmitButton text={_.toUpper(t('label_okay'))} disabled={submitting} />
        </Form>

        <div className="forgotPassword">
          <button
            id="forgotPassword"
            className="no_button"
            onClick={() => changeModal(authModalTypes.changePhone)}
          >
            {t('label_troubleSignup')}
          </button>
        </div>
      </div>
      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: 'verification'
})(Verification);
