import React, { PureComponent } from 'react';
import GifLoader from 'react-gif-loader';

class GifLoaderComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  imageStyle = {
    marginTop: '5%',
    height: '60%'
  }

  render() {
    return (
      <div className="content">
        <GifLoader
          loading={true}
          imageSrc="../../../static/imgs/loader2_edited.gif"
          overlayBackground="white"
          imageStyle={this.imageStyle}
        />
      </div>
    );
  }
}

export default GifLoaderComponent;
