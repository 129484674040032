import css from 'styled-jsx/css';
import colors from '../../../constants/colors';

export default css`
.login-submit-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-submit-button-container > button {
  padding: 16px 10px;
  font-size: 17px;
  font-weight: bold;
  min-height: 50px;
  min-width: 135px;
  cursor: pointer;
  color: #ffffff;
  border: none;
  background-color: #E74C3C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: all .3s ease;
}

.reset-submit-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.reset-submit-button-container > button {
  padding: 16px 10px;
  font-size: 17px;
  font-weight: bold;
  min-height: 50px;
  min-width: 135px;
  cursor: pointer;
  color: #ffffff;
  border: none;
  background-color: #E74C3C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: all .3s ease;
}

.regular-submit-button-container {
  width: 100%;
  text-align: right;
}

.regular-submit-button-container > button {
  padding: 10px 20px;
  border-radius: 4px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  background-color: ${colors.buttonColor};
  border: none;
  color: white;
  outline: none;
  transition: box-shadow .5s, opacity .5s;
  cursor: pointer;
}

.regular-submit-button-container > button:disabled {
  opacity: 0.6;
  cursor: default;
}

@media (max-width: 600px) {
  .login-submit-button-container > button {
    min-width: 155px;
    font-size: 11px;
    min-height: unset;
    padding: 12px 8px;
  }

  .reset-submit-button-container {
    margin-top: 30px;
  }

  .reset-submit-button-container > button {
    min-width: 155px;
    font-size: 11px;
    min-height: unset;
    padding: 12px 8px;
  }
}
`;
