import React from 'react';
import styles from './styles';

const renderFormError = ({
  error
}) => (
  <div className="form-error-container">
    {
      error &&
        <p>{error}</p>
    }

    <style jsx>{ styles }</style>
  </div>
);

export default renderFormError;
