import React from 'react';
import styles from './styles';
import Ionicon from 'react-ionicons';
import Button from '../Button'
import InputBox from '../InputBox'

import portals from '../../utils/portals';

let defaultButtons = [{
  name: 'Ok',
  handler: () => {}
}];

class Alert  extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        lastInputValue: ''
      }
    }

    render() {
      let innerButtons = this.props.buttons.length && this.props.buttons || defaultButtons;

      return (
        <React.Fragment>
          { !this.props.isAlertOpen ? '' : 
            <div className = 'alert-wrapper'>
              <div className='alert'>
                {
                  !this.props.showCross ? '' :
                    <button onClick={() => portals.closeAlert()}>
                      <Ionicon icon='md-close'  fontSize="25px" color='border: 3px solid ${colors.frameColor};'/>          
                    </button>
                }
                {this.props.title ? <div className='title'>{this.props.title}</div> : ''}
                {
                  this.props.inputCaption 
                    ?
                      <InputBox 
                        caption = {this.props.inputCaption}
                        placeholder = '...'
                        valueSetter = {
                          value => {
                            portals.setLastInputValue(value);
                            this.setState({lastInputValue: value});
                          }
                        }/>
                    : 
                      <div className='content'>
                        {this.props.content}
                      </div>  
                }
                <div className = 'button-wrapper'>
                  {
                    innerButtons.map((el, i) => 
                      (<span key = {i}>
                        <Button
                          locked = {!!this.props.inputCaption && i === (innerButtons.length - 1) && !this.state.lastInputValue.trim()}                          
                          handler = {() => {
                              portals.closeAlert();
                              this.setState({lastInputValue: ''});
                              el.handler();                        
                            }
                          }>
                          {el.name}
                        </Button>
                      </span>)
                    )
                  }
                </div>
              </div>
              <style jsx>{styles}</style>
            </div>
          }
        </React.Fragment>
      )
    }
  }

Alert.defaultProps = {
  buttons: defaultButtons
}

Alert.propTypes = {
};

export default Alert;
