import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import authModalTypes from '../../constants/authModalTypes';
import styles from './styles';
import { Form, reduxForm, FormSection } from 'redux-form';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';
import { required, phone } from '../ReduxForms/validators';
import PhoneFormSection from '../ReduxForms/PhoneFormSection';

const FORM_NAME = 'changePhone';

const ChangePhone = (props) => {
  const { t, handleSubmit, changeModal, error, submitting, onSubmit, dispatch } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_resetPasswordText')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form-text">
            Enter new phone
          </p>

          <FormSection name="phone">
            <PhoneFormSection t={t} validate={[required, phone]} id="phone" dispatch={dispatch} formName={FORM_NAME} />
          </FormSection>

          <FormError error={error} />

          <SubmitButton text={_.toUpper(t('label_next'))} disabled={submitting} />
        </Form>

        <div className="forgotPassword">
          <button
            id="forgotPassword"
            className="no_button"
            onClick={() => changeModal(authModalTypes.verification)}
          >
            {"return with old Phone"}
          </button>
        </div>
      </div>

      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: FORM_NAME,
})(ChangePhone);
