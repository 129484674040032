import css from 'styled-jsx/css';
import colors from '../../../constants/colors';

export default css`
.regular-container {
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}

.regular-container > label {
  width: 40%;
  padding: 5px 5px 5px 0;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, 'Montserrat', sans-serif;
  line-height: 20px;
  color: ${colors.inputLabel};
  text-align: left;
}

.regular-container > .default-input {
  font-size: 14px;
  font-weight: 600;
  font-family: Roboto;
  line-height: 20px;
  padding: 5px;
  outline: none;
  box-shadow: none;
  transition: all .5s;
  color: ${colors.inputLabel};
  box-sizing: border-box;
  border: none;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  position: relative;
  background-color: #fafbfc;
  border-radius: 3px;
}

.regular-container > :global(.mask-input) {
  font-size: 14px;
  font-weight: 600;
  font-family: Roboto;
  line-height: 20px;
  padding: 5px;
  outline: none;
  box-shadow: none;
  transition: all .5s;
  color: ${colors.inputLabel};
  box-sizing: border-box;
  border: none;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  position: relative;
  background-color: #fafbfc;
  border-radius: 3px;
}

.regular-container > .select-input {
  font-size: 14px;
  font-weight: 600;
  font-family: Roboto;
  line-height: 20px;
  padding: 5px;
  outline: none;
  box-shadow: none;
  transition: all .5s;
  color: ${colors.inputLabel};
  box-sizing: border-box;
  border: none;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  position: relative;
  background-color: #fafbfc;
  border-radius: 3px;
}

.regular-container > .default-input::placeholder {
  font-weight: 500;
  font-family: Roboto;
  color: ${colors.inputPlaceholder};
}

.regular-container > .select-input::placeholder {
  font-weight: 500;
  font-family: Roboto;
  color: ${colors.inputPlaceholder};
}

.greyedOut {
  background-color: #ededed !important;
  cursor: not-allowed;
}

@media (min-width: 600px) {
}
`;
