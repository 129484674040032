import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const Button = ({
    children,
    locked,
    inversed,
    bigFont,
    stretch,
    bookNow,
    theme,
    themeButton,
    themeButtonBlue,
    themeButtonGreen,
    themeButtonPink,
    themeButtonBrown,
    themeButtonRed,
    cancelButton,
    handler}) => (
  <React.Fragment>
    <button className = {`
      ${locked ? 'locked' : ''}
      ${inversed ? 'inversed' : ''}
      ${bigFont ? 'big-font' : ''}
      ${stretch ? 'stretch' : ''}
      ${bookNow ? 'bookNow' : ''}
      ${theme}
      ${themeButton ? 'themeButton': ''}
      ${themeButtonBlue ? 'themeButtonBlue': ''}
      ${themeButtonGreen ? 'themeButtonGreen': ''}
      ${themeButtonPink ? 'themeButtonPink': ''}
      ${themeButtonBrown ? 'themeButtonBrown': ''}
      ${themeButtonRed ? 'themeButtonRed': ''}
      ${cancelButton ? 'cancelButton': ''}`}
      onClick={locked ? () => {} : handler}>
      {children}
    </button>
    <style jsx>{styles}</style>
  </React.Fragment>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
