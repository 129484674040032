import React from 'react';
import styles from './styles';

const renderFormError = ({
  text = 'Submit',
  disabled = false,
  containerClass = 'login'
}) => (
  <div className={`${containerClass}-submit-button-container`}>
    <button
      type="submit"
      disabled={disabled}
    >
      {text}
    </button>
    <style jsx>{ styles }</style>
  </div>
);

export default renderFormError;
