import css from 'styled-jsx/css';

export default css`
.phone-field-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.phone-field-container > button {
  padding: 16px 10px;
  font-size: 17px;
  font-weight: bold;
  min-height: 50px;
  min-width: 135px;
  margin-bottom: 25px;
  cursor: pointer;
  color: #ffffff;
  border: none;
  background-color: #E74C3C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: all .3s ease;
}

.phone-field-container > select {
  flex: 0;
  padding: 12px 2px;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  margin-bottom: 5px;
  background:none;
  font-weight: 400;
}

@media (max-width: 600px) {
  .phone-field-container > button {
    min-width: 155px;
    font-size: 11px;
    min-height: unset;
    padding: 12px 8px;
  }
}
`;
