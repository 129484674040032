import React, { PureComponent } from 'react';
import { Field, change } from 'redux-form';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import Ionicon from 'react-ionicons';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import styles from './styles';

class PhoneFormSection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isValidPhone: null,
      isPhoneInputTouched: false,
    };

    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.debouncedOnPhoneNumberChange = _.debounce(this.onPhoneNumberChange, 600);
  };

  onPhoneNumberChange(isValid, phoneValue, selectedCountry) {
    const { formName, dispatch } = this.props;
    const { iso2 } = selectedCountry;

    let number;
    if (window.intlTelInputUtils && window.intlTelInputUtils.formatNumber) {
      const format = window.intlTelInputUtils.numberFormat.INTERNATIONAL;
      number = window.intlTelInputUtils.formatNumber(phoneValue, iso2, format);
    } else {
      number = phoneValue ? phoneValue : '';
    }

    dispatch(change(formName, 'phone.phone', number));
    dispatch(change(formName, 'phone.country', iso2.toUpperCase()));

    this.setState({
      isValidPhone: isValid,
    });
    this.props.setIsValidPhone && this.props.setIsValidPhone(isValid);
  };

  render() {
    const { state: { isValidPhone, isPhoneInputTouched }, props: { containerClass, id, submitTriggered } } = this;

    return(
      <div className={'phone-field-container ' + containerClass}>

        <Field
          component={IntlTelInput}
          style={{
            width: '100%',
            height: '50px',
            display: 'flex',
          }}
          props={{
            id: id,
            nationalMode: false,
            separateDialCode: true,
            preferredCountries: ['ch', 'de', 'li', 'it', 'fr'],
            css: ['intl-tel-input', 'form-control'],
            inputClassName: 'phone-input',
            autoHideDialCode: false,
            onPhoneNumberChange: this.debouncedOnPhoneNumberChange,
            onPhoneNumberBlur: () => this.setState({ isPhoneInputTouched: true }),
            format: true,
            formatOnInit: false,
          }}
        />

        {isPhoneInputTouched || submitTriggered ? (
          <Ionicon
            icon={isValidPhone ? 'md-done-all' : 'md-warning'}
            fontSize='30px'
            color={isValidPhone ? '#4ece3d' : '#d0021b'}
            style={{ marginLeft: '5px' }}
          />
        ) : null}

        <style jsx>{ styles }</style>
        <style jsx global>{`
          .phone-field-container > .intl-tel-input > input.phone-input {
            border: 1px solid #dfdfdf;
            border-radius: 3px;
            width: 100%;
          }

          .phone-field-container > div:first-child {
            flex: 3;
          }

          .phone-field-container >  div:last-child {
            flex: 7;
          }

          .phone-field-container > .mask-text-field-code {
            margin: 12px 7px 0;
            margin-top: 12px;
            font-weight: 400;
            font-size: 11px;
          }

          @media (min-width: 600px) {
            .phone-field-container > .mask-text-field-code {
              font-size: 13px;
              margin: 16px 7px 0;
              font-weight: 500;
            }
          }
      `}</style>
      </div>
    )
  }
}

PhoneFormSection.defaultProps = {
  containerClass: '',
  t: () => '',
  validate: []
};

PhoneFormSection.propTypes = {
  containerClass: PropTypes.string,
  t: PropTypes.func,
  validate: PropTypes.array
};

export default PhoneFormSection;
