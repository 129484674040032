export default [
  {
    countryName:'Switzerland',
    countryCode: '41',
    phoneMask: '99 999 99 99'
  }, 
  {
    countryName:'Germany',
    countryCode: '49',
    phoneMask: '9999 9999999'
  },
  {
    countryName:'Austria',
    countryCode: '43',
    phoneMask: '9999 9999999'
  }, 
  {
    countryName:'Liechtenstein',
    countryCode: '423',
    phoneMask: '9999 9999999'
  }, 
  {
    countryName:'Italy',
    countryCode: '39',
    phoneMask: '999 9999999999'
  },
  {
    countryName:'France',
    countryCode: '33',
    phoneMask: '99 999 99 99'
  } 
];
