import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import AuthModal from '../AuthModals/AuthModal';
import SetUserLayout from '../SetUserLayout/SetUserLayout';
import Portal from '../../hoc/Portal';
import Alert from '../../components/Alert';

const AppLayout = ({ children, t }) => (
  <SetUserLayout>
    <div className="app_layout">
      <div className="app_container">
        {children}
      </div>

      {Portal(Alert)}
      <AuthModal t={t} />

      <style jsx>{styles}</style>
    </div>
  </SetUserLayout>
);

AppLayout.defaultProps = {
  children: null,
};

AppLayout.propTypes = {
  children: PropTypes.node,
};

export default AppLayout;
