import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import { Form, reduxForm, FormSection } from 'redux-form';

const getConfig = require('next/config').default;

const publicRuntimeConfig = getConfig() ? getConfig().publicRuntimeConfig : null;

import SubmitButton from '../ReduxForms/SubmitButton';

const FORM_NAME = 'changePhone';

const SanamiaMig = (props) => {
  const { t, hash } = props;

  return (
    <DialogContent>
      <div className="container">
        <h3 className="container-header">
          {t('labelSanamiaMig')}
        </h3>
        <p>
          {t('textSanamiaMig1')}
        </p>
        <br/>
        <p>
          {t('textSanamiaMig2')}
        </p>
        <br/>
        <p>
          {t('textSanamiaMig3')}
        </p>
        <br/>
        <p>
          {t('textSanamiaMig4')}
        </p>
        <br/>
        <a href={`${publicRuntimeConfig.CONSUMER_URL_NEW}/signup/${hash}`} ><SubmitButton text={_.toUpper(t('textSanamiaButton'))} /></a>
      </div>

      <style jsx>{ styles }</style>
    </DialogContent>
  );
};

export default reduxForm({
  form: FORM_NAME,
})(SanamiaMig);
