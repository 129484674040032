import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
  .input-box {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
  }

  .input-box * {
    vertical-align: top;  
  }

  .input-box:not(.top-label) .label {
    padding-right: 5px;
  }

  .label, input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
  }

  .label, .input-area {
    box-sizing: border-box;
  }

  .label {
    width: 40%;
    padding: 5px 5px 5px 0;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 20px;
    color: ${colors.inputLabel};
    min-height: 30px;
    text-align: left;
  }

  .input-box.light .label {
    padding: 7px 7px 7px 0;
  }

  .input-area {
    width: 100%;
  }

  .input-area.with-label {
    width: 60%;
  }

  .top-label .label, .top-label .input-area {
    width: 100%;
  }

  .long-label .label, .long-label .input-area {
    width: 50%;
  }

  .input-box.wrong .input-area, .input-box.wrong.light .input-area {
    border: 1px solid ${colors.inputHintBorder};  
    background-color: rgba(255, 235, 235, 1);
  }

  .input-area {
    border: 1px solid ${colors.inputFrame};
    position: relative;
    background-color: #fafbfc;
    border-radius: 3px;
  }

  .input-area.slider {
    border-radius: 0;
    border: none;
    border-top: 1px solid ${colors.inputFrame};
    margin: 10px;
  }

  .slider-wrapper {
    position: relative;
  }

  .slider-wrapper > div:last-child {
    position: absolute;
    right: 0;
    top: -34px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    color: ${colors.inputLabel};
  }

  .input-box.light .input-area {
    border: none;
  }

  .input-box input, .input-box textarea {
    font-size: 14px;
    font-weight: 600;
    font-family: Roboto;
    line-height: 20px;
    padding: 5px;
    outline: none;
    box-shadow: none;    
    transition: all .5s;
    color: ${colors.inputLabel};
    box-sizing: border-box;
    border: none;
    width: 100%;
    border-radius: 3px;
    background-color: transparent;
    resize: none;
  }

  .input-box.light input {
    padding: 7px;
  }

  .input-area.with-icon input {
    width: calc(100% - 32px);
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .input-box input:focus, .input-box textarea:focus {
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, .2);
  }

  .input-box.light input:focus, .input-box.light textarea:focus {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .5);
  }


  .input-box input:disabled, .input-box textarea:disabled {
    background-color: transparent;
  }

  .input-box input::placeholder, .input-box textarea::placeholder {
    font-weight: 500;
    font-family: Roboto;
    color: ${colors.inputPlaceholder};
  } 

  .input-box input.with-icon:focus {
    border-bottom: 1px solid transparent;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 4px 6px;
    margin: 0;
  }

  .input-box.light button {
    padding: 6px 6px;
  }

  .dropdown {
    width: 100%;
    position: absolute;
    top: calc(100% - 4px);
    left: -1px;
    background-color: white;
    border: 1px solid ${colors.inputFrame};
    border-bottom-right-radius: 4px; 
    border-bottom-left-radius: 4px; 
    z-index: 100;
    max-height: 200px;
    overflow: auto;
  }
  
  .glass {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;;
    height: 100vh;
    z-index: 99;
  }

  .dropdown div {
    transition: all .5s;
    color: ${colors.inputLabel};
    padding: 5px;
    cursor: pointer;
  }

  .dropdown div:hover {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, .2);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  
  .required::after {
    content: '*';
    color: red;
    font-size: 15px;
    font-family: Roboto;
    position: absolute;
    right: -10px;    
    top: 0;
  }

  .hint {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: calc(100%);
    background-color: #ffffc4;
    color: ${colors.headerColor};
    border: 1px solid ${colors.headerColor};
    border-radius: 4px;    
    padding: 5px;
    box-sizing: border-box;
    z-index: 10;    
    animation-name: popup-animation;
    animation-duration: .4s;
    animation-timing-function: linear;
  }

  .input-box.light .hint {
    background-color: white;
    color: ${colors.itemColor};
    border: 1px solid ${colors.itemColor};
  }

  .input-box.light .hint span {    
    border-bottom: 5px solid white;
  }

  .hint span {
    position: absolute;
    border: 5px solid transparent;
    border-bottom: 5px solid ${colors.headerColor};
    width: 10px;
    padding: 0;
    left: 5px;
    top: -11px;
    box-sizing: border-box; 
  }

  @keyframes popup-animation {
    from {top: calc(100% + 50px); opacity: 0;}
    to {top: calc(100% + 6px); opacity: 1;}
  }

  .rc-slider-track {
    border-top: 1px solid red;
  }
`;
