import css from 'styled-jsx/css';

export default css`
:global(.input-error) {
  outline-offset: -2px;
  outline-color: red;
  outline-style: auto;
}

.error-message {
   color: red;
}
`;
