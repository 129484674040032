import css from 'styled-jsx/css';
import colors from '../../../constants/colors';

export default css`
.form-error-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.form-error-container > p {
  margin-bottom: 15px;
  color: ${colors.errorMessage}
}
`;
