import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import authModalTypes from '../../constants/authModalTypes';
import styles from './styles';
import { Form, Field, reduxForm } from 'redux-form';
import FormField from '../ReduxForms/FormField';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';
import { required, numeric, exactLength } from '../ReduxForms/validators';

const exact4 = exactLength(4);

const Code = (props) => {
  const { t, handleSubmit, phone, changeModal, error, submitting, onSubmit } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_codeMessage1')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form-text" align="center">
            {t('label_codeMessage2')}
            <br />
            {phone ? `+${phone}` : t('label_yourPhone')}
          </p>

          <Field name="code" component={FormField} label="code" t={t} validate={[required, numeric, exact4]} />

          <FormError error={error} />

          <SubmitButton text={_.toUpper(t('label_next'))} disabled={submitting} />
        </Form>

        <div className="forgotPassword">
          <button
            id="forgotPassword"
            className="no_button"
            onClick={() => changeModal(authModalTypes.signIn)}
          >
            {t('label_accountAlready')}
          </button>
        </div>
      </div>

      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

export default reduxForm({
  form: 'code'
})(Code);
