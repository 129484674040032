import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
  .blue {
    background-color: ${colors.buttonColorBlue};
  }

  .green {
    background-color: ${colors.buttonColorGreen};
  }

  .pink {
    background-color: ${colors.buttonColorPink};
  }

  .brown {
    background-color: ${colors.buttonColorBrown};
  }

  .cancelButton {
    color: #4e4e4e;
    background-color: white !important;
    border: solid 1px #4e4e4e;
  }

  button.themeButton {
    width: 120px;
  }

  button.themeButtonBlue{
    background-color: #0079c4;
  }

  button.themeButtonGreen{
    background-color: #2dc400;
  }

  button.themeButtonPink{
    background-color: #c000c4;
  }

  button.themeButtonBrown{
    background-color: #c47800;
  }
  
  button.themeButtonRed{
    background-color: #FF6D70;
  }

  button {
    padding: 10px 20px;
    border-radius: 4px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    background-color: ${colors.buttonColor};
    border: none;
    color: white;
    outline: none;  
    transition: box-shadow .5s;
  }

  button.bookNow {
    background-color: #E74C3C;
    border: none;
    font-weight: 500;
    font-family: Roboto;
    height: 55px;
    width: 100%;
  }

  button.inversed {
    border: 1px solid #ffffff;
    background-color: transparent
  }

  button.stretch {
    width: 100%;
  }

  button.locked {
    opacity: .5;
  }

  button.big-font {
    font-size: 21px;
    font-weight: 700;
    line-height: 28px;
  }

  button:not(.locked):hover {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, .2);
    cursor: pointer;
  }

  button.inversed:hover {
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, .2);
    cursor: pointer;
  }

  button:not(.inversed).locked:hover {
    box-shadow: none;
    cursor: initial;
  }

  @media (max-width: 480px) {
    button {
      padding: 10px;
    }  
  }

  
@media (max-width:750px) {
  button.bookNow {
    height: 50px;
    padding: 0px;
  }
}
`;
