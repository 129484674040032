import * as _ from 'lodash';
import React from 'react';
import InputMask from 'react-input-mask';
import commonStyles from './commonStyles';
import loginStyles from './loginStyles';
import regularStyles from './regularStyles';

const formField = ({
  id,
  input,
  t,
  label,
  placeholder = null,
  containerClass = 'login',
  greyedOut = false,
  type = 'text',
  meta: { touched, error },
  children,
  ...custom
}) => (
  <div className={`${containerClass}-container`}>
    {
      label &&
        <label htmlFor={_.snakeCase(label)}>
          {t(`label_${_.camelCase(label)}`)}
        </label>
    }

    {
      type === 'mask' &&
        <InputMask
          id={id}
          placeholder={placeholder || ''}
          type={type}
          className={`mask-input ${touched && error ? 'input-error' : ''}`}
          {...input}
          {...custom}
        />
    }

    {
      type === 'select' &&
        <select
          id={id}
          placeholder={placeholder || ''}
          className={`select-input ${touched && error ? 'input-error' : ''}`}
          {...input}
          {...custom}
        >
          {children}
        </select>
    }

    {
      type !== 'mask' && type !== 'select' &&
        <input
          id={_.snakeCase(label)}
          placeholder={placeholder || t(`P.${_.camelCase(label)}`)}
          type={type}
          className={`default-input ${touched && error ? 'input-error' : ''} ${greyedOut ? 'greyedOut' : ''}`}
          {...input}
          {...custom}
        />
    }

    {
      touched && error &&
        <span className="error-message">{error}</span>
    }


    <style jsx>{commonStyles}</style>
    <style jsx>{loginStyles}</style>
    <style jsx>{regularStyles}</style>
  </div>
);

export default formField;
