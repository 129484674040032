import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import authModalTypes from '../../constants/authModalTypes';
import styles from './styles';
import { Form, Field, FormSection, reduxForm, formValueSelector } from 'redux-form';
import FormField from '../ReduxForms/FormField';
import PhoneFormSection from '../ReduxForms/PhoneFormSection';
import FormError from '../ReduxForms/FormError';
import SubmitButton from '../ReduxForms/SubmitButton';
import { Link } from '../../../routes';
import { required, email, password, min3, phone} from '../ReduxForms/validators';
import * as _ from 'lodash';
import { connect } from 'react-redux';

const FORM_NAME = 'signUp';

const SignUp = (props) => {
  const { t, handleSubmit, changeModal, error, submitting, onSubmit, dispatch, submitTriggered, setIsValidPhone } = props;

  return (
    <DialogContent>
      <div className="container">
        <h2 className="container-header">
          {t('label_createAccount')}
        </h2>

        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Field name="firstName" component={FormField} label="first name" t={t} validate={[required, min3]} />
          <Field name="lastName" component={FormField} label="last name" t={t} validate={[required, min3]} />
          <Field name="email" component={FormField} label="email" t={t} validate={[required, email]} />

          <div className="d-flex justify-content-start">
            <label htmlFor="phone" className="phone-label">
              {t(`label_phone`)}
            </label>
          </div>

          <FormSection name="phone">
            <PhoneFormSection
              t={t}
              validate={[required, phone]}
              id="phone"
              dispatch={dispatch}
              formName={FORM_NAME}
              setIsValidPhone={(isValid) => setIsValidPhone(isValid)}
              submitTriggered={submitTriggered}
            />
          </FormSection>

          <Field name="password" component={FormField} label="password" type="password" t={t} validate={[required, password]}/>

          <div style={{marginBottom: "5px"}}>
            {t('validator.password')}
          </div>

          <FormError error={error} />

          <SubmitButton text={_.toUpper(t('label_signup'))} disabled={submitting} />

        </Form>

        <div className="policy">
          <p>
            {t('label_policy1')}
            <p></p>
            <a className="footerLinks" href="https://softrey.ch/dsg/Softrey-AGB-Sep-2023-DE.pdf">
              {t('label_termsOfServices')}
            </a>
            {t('label_policy2')}
            <a className="footerLinks" href="https://softrey.ch/dsg/Softrey-DSE-Sep-2023-DE.pdf">
              {t('label_privacyPolicy')}
            </a>
            <p></p>
            {t('label_policy3')}
          </p>
        </div>

        <div className="accountAlready">
          <button
            id="accountAlready"
            className="no_button"
            onClick={() => changeModal(authModalTypes.signIn)}
          >
            {t('label_accountAlready')}
          </button>
        </div>
      </div>
      <style jsx>{ styles }</style>
    </DialogContent>
  )
};

const selector = formValueSelector('signUpData');

const mapStateToProps = (state) => ({
  initialValues: state.app.signUpData,
  initialPhone: selector(state, 'phone')
});

export default connect(mapStateToProps)(reduxForm({ form: FORM_NAME, enableReinitialize: true })(SignUp));
